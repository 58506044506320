import { Component, OnInit, OnDestroy } from '@angular/core';
import { interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-moving-coin',
  templateUrl: './moving-coin.component.html',
  styleUrls: ['./moving-coin.component.scss']
})
export class MovingCoinComponent implements OnInit, OnDestroy {

  outerRotation = 0;
  innerRotation = 0;
  centerRotation = 0;

  private outerInterval = interval(7000);
  private innerInterval = interval(2000);
  private destroy$ = new Subject();

  constructor() { }

  ngOnInit() {
    this.outerInterval.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.outerRotation += 360;
    });
    this.innerInterval.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.innerRotation += 360;
      this.centerRotation -= 360;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

}
