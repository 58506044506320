import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { MovingCoinComponent } from './moving-coin/moving-coin.component';
import { PortfolioItemComponent } from './portfolio-item/portfolio-item.component';
import { FlashingColorsDirective } from './flashing-colors.directive';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    MovingCoinComponent,
    PortfolioItemComponent,
    FlashingColorsDirective
  ],
  imports: [
    BrowserModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
