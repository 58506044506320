import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-portfolio-item',
  templateUrl: './portfolio-item.component.html',
  styleUrls: ['./portfolio-item.component.scss']
})
export class PortfolioItemComponent implements OnInit {
  @Input() image: string;
  @Input() title: string;
  @Input() description: string;
  @Input() link: string;
  @Input() uid: string;

  constructor() { }

  ngOnInit() {
  }

  goToLink() {
    window.open(this.link, '_blank');
  }

}
