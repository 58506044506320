import { Component, OnInit, OnDestroy } from "@angular/core";
import { interval, Subject } from "rxjs";
import { takeUntil, take } from "rxjs/operators";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  length = 0;
  activeTab = 0;
  tabs = [{ title: "Projects" }, { title: "About" }];
  enterpriseApps = [
    {
      image: "i-d.png",
      title: "VICE Media Group",
      description:
        "Digital publishing across multiple brands including VICE.com, i-d.vice.com, and refinery29.com. Lead and mentored a team of developers, advised on large-scale architectural decisions across the company, and worked on a complete redesign/relaunch of i-d.vice.com. Tech Stack: React, Next.js, TypeScript, SASS, Styled Components, Node.js, Jest",
      link: "https://i-d.vice.com/",
      uid: "vice",
    },
    {
      image: "onetrust.png",
      title: "OneTrust",
      description:
        "Data Privacy Management software focused on compliance with regulations such as GDPR, CCPA, etc. Worked in the cookie consent compliance module, including a cookie consent banner that is found all across the internet. Architected a cookie auto-blocking solution from concept to production in 10 days that made the company millions. Initiatives include major Architect work implementing git submodule based micro-frontends and leading the push for comprehensive unit testing product-wide. Tech Stack: Angular, TypeScript, SASS, Jasmine",
      link: "https://www.onetrust.com/",
      uid: "onetrust",
    },
    {
      image: "adp.png",
      title: "myADP",
      description:
        "HR management software for small and large companies. Worked in the user profile section, including mobile integrations. Additionally created QA automation tests for functionality and helped toward an AngularJS to Angular migration. Tech Stack: AngularJS, Angular, TypeScript, Jasmine, SASS, LESS, Protractor",
      link: "https://my.adp.com/",
      uid: "adp",
    },
  ];
  portfolioItems = [
    {
      image: "battlefield.png",
      title: "D&D Battlefield",
      description:
        "A live D&D battlefield session utilizing websockets. Tech Stack: Angular 10, Bootstrap, SASS, Angular Material, Websockets, Node.js, Express",
      link: "http://dnd-battlefield.herokuapp.com/",
      uid: "battlefield",
    },
    {
      image: "deathstar.png",
      title: "Death Star Dashboard",
      description:
        "A demo collection of various common UX paradigms, in a Star Wars theme with Material design. Tech Stack: Angular 10, Bootstrap, SASS, Angular Material",
      link: "http://deathstar.briankellydev.com/",
      uid: "deathstar",
    },
    {
      image: "beepboop.png",
      title: "BeepBoop",
      description:
        "Audio synthesis DAW for making electronic music. Tech Stack: Angular 7, Tone.js, Bootstrap, SASS",
      link: "https://beepboop.briankellydev.com/",
      uid: "beepboop",
    },
  ];
  mediaItems = [
    {
      image: "unit-test.png",
      title: "Unit Testing Angular with Jasmine",
      description:
        "YouTube tutorial series focused on unit testing in a modern Angular environment",
      link: "https://www.youtube.com/playlist?list=PLfQrb-Qyr87JvnUIwasG8CubWAen00dGX",
      uid: "unit-testing",
    },
  ];
  private myInterval = interval(38000);
  private destroy$ = new Subject();

  ngOnInit() {
    this.myInterval.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.length -= 3284;
    });
    setTimeout(() => {
      this.length -= 3284;
    }, 10);
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  setActive(tab: number) {
    this.activeTab = tab;
  }
}
