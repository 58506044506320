import { Directive, OnDestroy, OnInit, ElementRef } from '@angular/core';
import { interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appFlashingColors]'
})
export class FlashingColorsDirective implements OnInit, OnDestroy {

  private colorInterval = interval(100);
  private destroy$ = new Subject();
  private COLOR_ARRAY = ['#24C0EB', '#66ff66', '#FFCD37', '#FF2B73'];
  private currentCount = 0;

  constructor(private el: ElementRef) { }

  ngOnInit() {
    this.colorInterval.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.el.nativeElement.style.color = this.COLOR_ARRAY[this.currentCount];
      this.currentCount = this.currentCount === this.COLOR_ARRAY.length - 1 ? 0 : this.currentCount + 1;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

}
